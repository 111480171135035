import { ErrorMessage, Formik, Form as FormFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../../../components/Button";
import { SigapConfigurationFields, SigapConfigurationForm, SigapConfigurationInitialValues } from "../form.schema";
import { resolveErrors } from "../../../../utils/errorTypes";
import { getGlobalSettingsService, updateGlobalSettingsService } from "../../../../utils/apiCalls";
import { toast } from "../../../../components/Toast";
import { GLOBAL_SETTINGS_KEY } from "../../../../utils/constant";
import { Divider } from "@mui/material";
import InputsControl from "../../../../components/Form/InputsControl";
import { Loading } from "../../../../components/Preloader";

export const SigapConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialFormValues, setInitialFormValues] = useState(SigapConfigurationInitialValues);

  const onSubmit = async (formValues) => {
    setLoading(true);
    try {
      await updateGlobalSettingsService({
        [GLOBAL_SETTINGS_KEY.SIGAP_CONFIG]: formValues
      });
      toast("Salvo com sucesso!", "success");

    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao obter resultados`), "error");
    } finally {
      setLoading(false);
    }
  };

  const loadGlobalSettings = async () => {
    setInitialLoading(true);
    try {
      const { data: { data } } = await getGlobalSettingsService({ keys: [GLOBAL_SETTINGS_KEY.SIGAP_CONFIG] });
      setInitialFormValues(data.settings[GLOBAL_SETTINGS_KEY.SIGAP_CONFIG]);
    } catch (err) {
      const message = resolveErrors(err.response.data.errors, "Erro ao obter configurações do sigap");
      toast(message, "error");
    } finally {
      setInitialLoading(false);
    }
  }

  useEffect(() => {
    loadGlobalSettings();
  }, [])

  return (<>
    <div className="TableBg mt-4 pt-1">
      <div className="section-header-wrap m-0 mt-2 py-3 px-3">
        <h2 className="title-text">Configurações</h2>
      </div>
      <div className='position-relative h-100 p-3'>
        <Formik
          initialValues={initialFormValues}
          validationSchema={SigapConfigurationForm()}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue, handleSubmit, is }) => {
            return (
              <FormFormik autoComplete="off">
                <Row>
                  {!initialLoading
                    ? <InputsControl {...{ fieldsConfig: SigapConfigurationFields, handleChange, setFieldValue, values, initialFormValues }} />
                    : <Loading className={"position-absolute bg-white top-0 w-100 h-100"} />
                  }
                </Row>
                <Row>
                  <Col>
                    <Button className={"ms-auto d-block"} onClick={handleSubmit} loading={loading} >
                      Salvar alterações
                    </Button>
                  </Col>
                </Row>
              </FormFormik>
            )
          }}
        </Formik>
      </div>
    </div>
  </>)
};

export default SigapConfiguration;