import * as Yup from 'yup';

export const sigapFormSchema = (fields = []) => Yup.object().shape({
  tipoArquivo: Yup.number().required('Selecione um tipo de arquivo'),
  ...(fields.includes('sigapOperatorId') ? {
    sigapOperatorId: Yup.string().required('Selecione uma marca'),
  } : {}),

  justificativaRetificacao: Yup.string()
    .when(['tipoArquivo'], (tipoArquivo, schema) => {
      return tipoArquivo == 2 ? schema.required('Informe a justificativa da retificação') : schema;
    }),

  files: Yup.mixed().test(
    "required",
    "Envie os arquivos XML",
    (files) => files?.length > 0)
});

export const SigapOperatorDailyForm = () => Yup.object().shape({
  sigapOperatorId: Yup.string().required('Selecione uma marca'),
  dataMovimentoRef: Yup.date().required('Selecione a data de referência'),
  saldoTotalContTrans: Yup.number().min(0),
  saldoFinTotalDisp: Yup.number().min(0),
  valorTotalApostasCurso: Yup.number().min(0),
});

export const SigapOperatorMonthyForm = () => Yup.object().shape({
  sigapOperatorId: Yup.string().required('Selecione uma marca'),
  dataMovimentoRef: Yup.date().required('Selecione a data de referência'),
  ggrTotal: Yup.number().min(0),
  mediaTempoAcessoDiario: Yup.number().min(0),
});

export const SigapOperatorDailyInitialValues = {
  sigapOperatorId: '',
  dataMovimentoRef: '',
  saldoTotalContTrans: 0,
  saldoFinTotalDisp: 0,
  valorTotalApostasCurso: 0,
}
export const SigapOperatorMonthlyInitialValues = {
  sigapOperatorId: '',
  dataMovimentoRef: '',
  ggrTotal: 0,
  mediaTempoAcessoDiario: 0,
}

export const tipoArquivos = [
  { label: 'Original', description: '', value: 0 },
  { label: 'Retificadora', description: '', value: 2 },
  { label: 'Correção', description: '', value: 3 },
];

export const tableHeaders = [
  { label: 'ID' },
  { label: 'Marca', className: "text-left" },
  { label: 'Tipo Lote', className: "text-left" },
  { label: 'Tipo Arquivo' },
  { label: 'Tipo Recibo' },
  { label: 'Periodicidade' },
  { label: 'Data Lote' },
  { label: 'Download', className: "text-left" },
  { label: 'Ação' },
  { label: 'Enviado em:' },
];

export const SigapMovimentoSearchInitialValues = {
  sigapOperatorId: '',
};

export const SigapMovimentoSearchForm = () => Yup.object().shape({
  sigapOperatorId: Yup.string().required('Selecione uma marca'),
});

export const SigapConfigurationInitialValues = {
  currentAPI: '',
  HOMOLOG_AUTH_API_URL: '',
  HOMOLOG_API_URL: '',
  PRODUCTION_AUTH_API_URL: '',
  PRODUCTION_API_URL: '',
};

export const SigapConfigurationForm = () => Yup.object().shape({
  currentAPI: Yup.string().required('Escolha qual API usar'),
  HOMOLOG_API_URL: Yup.string().required('Informe a api de Homolog'),
  HOMOLOG_AUTH_API_URL: Yup.string().required('Informe a api autenticação de Homolog'),
  PRODUCTION_API_URL: Yup.string().required('Informe a api de Produção'),
  PRODUCTION_AUTH_API_URL: Yup.string().required('Informe a api autenticação de Produção'),
});

const SigaConfigCurrentApiOptions = [
  {
    label: "Produção",
    value: "production"
  },
  {
    label: "Homolog",
    value: "homolog"
  },
]

export const SigapConfigurationFields = [
  { label: "API Atual", name: 'currentAPI', className: "col-sm-4 col-12", type: "select", options: SigaConfigCurrentApiOptions },


  { separator: "API PRODUÇÃO" },
  { label: "URL de autenticação", name: 'PRODUCTION_AUTH_API_URL', type: "text", className: "col-sm-12 col-12" },
  { label: "URL dos endpoints", name: 'PRODUCTION_API_URL', type: "text", className: "col-sm-12 col-12" },

  { separator: "API HOMOLOG" },
  { label: "URL de autenticação", name: 'HOMOLOG_AUTH_API_URL', type: "text", className: "col-sm-12 col-12" },
  { label: "URL dos endpoints", name: 'HOMOLOG_API_URL', type: "text", className: "col-sm-12 col-12" },

]