import React from "react";
import Trigger from "../OverlayTrigger";
import { InputGroup, Col } from '@themesberg/react-bootstrap';
import { Form as BForm } from 'react-bootstrap';
import PercentInput from "../PercentInput";
import Select from 'react-select';
import { get } from "object-path";
import FormatNumber from "../../utils/formatNumber";
import CurrencyInput from "../CurrencyInput";
import { FormikErrorMessage } from "../ErrorMessage";
import UploadImageInput from "../UploadImageInput";
import { ErrorMessage } from "formik";

export const InputsControl = ({ fieldsConfig, values, setFieldValue = () => null, handleChange = () => null, initialFormValues }) => {
  if (!initialFormValues) initialFormValues = values;

  return (<>
    {initialFormValues && fieldsConfig && fieldsConfig.map(({ label, name, type = "text", options = [], isMulti, className = "col-sm-3 col-12", separator, disabled, message, step, title, subtitle, feedback, showIfValue, inputClassName, onChange = () => null, ...props }, key) => {

      return (!showIfValue || (showIfValue && Boolean(get(values, showIfValue)))) ? <>
        {(!separator && !title && !subtitle) ?
          <Trigger {...{ message }} placement='top' key={key}>
            <Col className={`mb-3 d-flex flex-column ${className}`} key={key}>
              {!["checkbox", "switch"].includes(type) ? <BForm.Label>{label}</BForm.Label> : null}
              <InputGroup>
                {type == "currency" ?
                  <CurrencyInput
                    {...{ type, name, onChange: handleChange, disabled }}
                    placeholder={FormatNumber.currency(0)}
                    defaultValue={FormatNumber.currency(get(initialFormValues, name))}

                    onNumberFormat={(event) => {
                      setFieldValue(name, event.detail.number)
                    }}
                  /> : null}
                {type == "percent" ?
                  <PercentInput
                    {...{ type, name, onChange: handleChange, disabled }}
                    placeholder={FormatNumber.percent(0)}
                    defaultValue={FormatNumber.percent(get(initialFormValues, name))}

                    onNumberFormat={(event) => {
                      setFieldValue(name, event.detail.number)
                    }}
                  /> : null}
                {type == "select" & !isMulti ?
                  <BForm.Select
                    {...{ type, name, value: get(values, name) || '', disabled }}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (Number(value)) value = Number(value);
                      if (["true", "false", "null"].includes(value)) value = JSON.parse(value);
                      setFieldValue(name, value)
                      onChange(value)
                    }}
                  >
                    {options && options.map(({ label, value }, key) => <option {...{ value, key }}>{label}</option>)}
                  </BForm.Select>
                  : null}
                {type == "select" & isMulti ?
                  <Select
                    {...{ type, name, options, disabled }}
                    isMulti
                    isClearable={false}
                    className='basic-multi-select w-100'
                    classNamePrefix='select'
                    value={get(values, name) || ''}
                    onChange={(values, e) => {
                      setFieldValue(name, values)
                      onChange(values)
                    }}
                  /> : null}
                {["checkbox", "switch"].includes(type) ?
                  <BForm.Check
                    reverse
                    {...{ type, name, label, feedback, checked: get(values, name), disabled, id: name }}
                    onChange={({ target }) => {
                      setFieldValue(name, target.checked);
                    }}
                  /> : null}
                {["upload-image"].includes(type) ?
                  <UploadImageInput
                    {...{ type, name, label, disabled }}
                    src={get(values, name)}
                    onChangeFile={(file) => {
                      setFieldValue(name, file);
                    }}
                  /> : null}
                {/* Default input */}
                {(!type || ["text", "number"].includes(type)) ? <BForm.Control {...{ type, name, onChange: handleChange, value: get(values, name), disabled, step }} className={inputClassName} {...props} /> : null}

              </InputGroup>
              {(!["checkbox", "switch"].includes(type) && feedback) ? <BForm.Control.Feedback className='d-block text-feedback'>{feedback}</BForm.Control.Feedback> : null}
              <ErrorMessage name={name} component='small' className='text-danger mt-1' showIfEmpty={false} />
            </Col>
          </Trigger> : null}
        {separator ? <div className='mt-3 mb-3 d-flex gap-2 color-blue-800' key={key}>{typeof separator == 'string' && <span className='mw-fit'>{separator}</span>}<hr className='w-100' /></div> : null}
        {title ? <h4 className='mt-3 mb-3 d-flex gap-2 color-blue-800' key={key}><span className='mw-fit'>{title}</span><hr className='w-100' /></h4> : null}
        {subtitle ? <h6 className='mt-3 mb-2 d-flex gap-2 color-blue-800' key={key}><span className='mw-fit'>{subtitle}</span></h6> : null}
      </> : null
    })}
  </>)
};

InputsControl.BooleanSelectOptions = (yes = "Sim", no = "Não") => [
  { label: "Selecione", value: '' },
  { label: yes, value: true },
  { label: no, value: false }
]

InputsControl.CreateSelectOptions = (data = [], valueKey = 'value', labelKey = 'label') => {
  return [
    { label: "Selecione", value: '' },
    ...data.map(e => ({ value: typeof valueKey == 'function' ? valueKey(e) : get(e, valueKey), label: typeof labelKey == 'function' ? labelKey(e) : get(e, labelKey) }))
  ]
};


export default InputsControl;