import "./styles.scss";
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Tab } from "@themesberg/react-bootstrap";

import { toast } from "../../../components/Toast";
import { resolveErrors } from "../../../utils/errorTypes";

import _ from "lodash";
import { sigapService } from "../../../utils/apiCalls";
import SigapLotesReportTable from "./components/LotesReportTable";

import SigapSendLotesFiles from "./components/SendLotesFiles";
import SigapExportLotesFiles from "./components/ExportLotesFiles";
import SigapRegisterOperatorDayValues from "./components/RegisterOperatorDailyValues";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AdminsRoutes } from "../../../routes";
import RegisterOperatorMonthlyValues from "./components/RegisterOperatorMonthlyValues";
import SigapMovimentoSearch from "./components/MovimentoSearch";
import SigapConfiguration from "./components/Configuration";

export const SigapPage = () => {
  const navigate = useNavigate();
  const [marcas, setMarcas] = useState({});
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get("tab") ?? 'sended-reports');

  const getSigapOperators = async () => {
    try {
      const marcas = await sigapService.getSigapOperators({ limit: 7, pageNo: 1 });
      setMarcas(marcas.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao obter relatório de últimos envios`), "error");
    }
  }

  const dataFileItems = [
    {
      name: "Apostadores",
      daily: true,
      sendService: sigapService.sendSigapBettors,
      exportService: sigapService.exportSigapBettors,
      sendTypeMessage: "Envio por marca",
      fields: ['sigapOperatorId'],
    },
    {
      name: "Carteiras",
      daily: true,
      sendService: sigapService.sendSigapUserWallets,
      exportService: sigapService.exportSigapUserWallets,
      sendTypeMessage: "Envio por marca",
      fields: ['sigapOperatorId'],
    },
    {
      name: "Jogos Online",
      daily: true,
      sendService: sigapService.sendSigapDailyGames,
      exportService: sigapService.exportSigapDailyGames,
      sendTypeMessage: "Envio por marca",
      fields: ['sigapOperatorId'],
    },
    {
      name: "Apostas Esportivas",
      daily: true,
      sendService: sigapService.sendSigapSportsBetting,
      exportService: sigapService.exportSigapSportsBetting,
      // disabled: true,
      sendTypeMessage: "Envio por marca",
      fields: ['sigapOperatorId'],
    },
    {
      name: "Operador Diário",
      daily: true,
      sendService: sigapService.sendSigapDailyOperators,
      exportService: sigapService.exportSigapDailyOperators,
      sendTypeMessage: "Envio por agente operador",
      fields: [''],
    },
    {
      name: "Operador Mensal",
      exportWarning: "Selecione uma data qualquer referente ao mês do relatório",
      monthly: true,
      sendService: sigapService.sendSigapMonthlyOperators,
      exportService: sigapService.exportSigapMonthlyOperators,
      sendTypeMessage: "Envio por agente operador",
      fields: [''],
    },
  ];

  useEffect(() => {
    getSigapOperators()
  }, []);

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3 mb-4">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">SIGAP</h2>
          </Col>
        </Row>
      </div>
      <Tabs
        className='nav-light'
        activeKey={selectedTab}
        onSelect={(tab) => {
          setSelectedTab(tab);
          navigate(`${AdminsRoutes.Sigap}?tab=${tab}`)
        }}
        mountOnEnter
        unmountOnExit
      >
        <Tab eventKey='sended-reports' title='Relatórios enviados'>
          <SigapLotesReportTable />
        </Tab>
        <Tab eventKey='export-reports' title='Exportar relatórios'>
          <SigapExportLotesFiles {...{ dataFileItems }} />
        </Tab>
        <Tab eventKey='record-daily-operation' title='Registrar operações diárias'>
          <SigapRegisterOperatorDayValues {...{ marcas }} />
        </Tab>
        <Tab eventKey='record-monthly-operation' title='Registrar operações Mensais'>
          <RegisterOperatorMonthlyValues {...{ marcas }} />
        </Tab>
        <Tab eventKey='send-reports' title='Enviar relatórios'>
          <SigapSendLotesFiles {...{ dataFileItems, marcas }} />
        </Tab>
        <Tab eventKey='movimento-search' title='Movimento'>
          <SigapMovimentoSearch {...{ dataFileItems, marcas }} />
        </Tab>
        <Tab eventKey='configuration' title='Configurações'>
          <SigapConfiguration />
        </Tab>
      </Tabs>
    </>
  );
};

export default SigapPage;
